import Axios from './../../components/_axios';
import React, { useState } from "react";
import classnames from 'classnames';

export default function PosterPage () {

  const [ content, setContent ] = useState('');
  const [ dropdownActive, setDropdownActive ] = useState(false);
  const [ imgSrc, setImgSrc ] = useState('');

  const makePoster = posterType => {
    console.log('XXX');
    Axios.post(
      '/action/weather/poster',
      { city: content, poster: posterType }
  ).then(res => {
      if (res.data.success) setImgSrc(res.data.imageUrl);
      setDropdownActive(false);
  });
}

  return <div>
      <br />
      <div className='columns'>
          <div className='column is-3 is-hidden-mobile'></div>
          <div className='column is-5'>
              <div className='control is-expanded'>
                  <input
                      className='input is-rounded'
                      type='text'
                      placeholder='输入站名或站号'
                      value={content}
                      onChange={e => setContent(e.target.value)}
                  />
              </div>
          </div>
          <div className='column is-1'>
              <div className='control is-expanded'>
                  <div className={classnames({
                      'dropdown': true,
                      'is-active': dropdownActive
                  })}>
                      <div className='dropdown-trigger'>
                          <button
                              className='button is-rounded'
                              onClick={() => setDropdownActive(prev => !prev)}
                          >
                              <span>选择……</span>
                          </button>
                      </div>
                      <div className='dropdown-menu' id='dropdown-menu' role='menu'>
                          <div className='dropdown-content'>
                              <a
                                  className='dropdown-item'
                                  onClick={() => makePoster('precip24vertical')}
                              >24小时降水（竖版）</a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className='column is-3 is-hidden-mobile'></div>
      </div>
      <br />
      <div className='columns'>
          <div className='column is-3 is-hidden-mobile'></div>
          <div className='column is-6'>
              { imgSrc !== '' && <img src={imgSrc} />}
          </div>
          <div className='column is-3 is-hidden-mobile'></div>
      </div>
  </div>

}
