import React, { Component } from 'react'
import PanelList from '../../components/panellist';
import ImageBox from '../../components/imagebox';
import classnames from 'classnames';
import Axios from '../../components/_axios';


function pad(num, size) {
  var s = num+"";
  while (s.length < size) s = "0" + s;
  return s;
}

export default class RealTimeMapPage extends Component {

  constructor(props) {
    super(props);

    this.areas = ['中国', '24小时变温', '华南', '华东', '华北', '东南', '台灣', '华中', '东北', '云贵', '川渝', '晋陕', '甘青', '新疆', '西藏'];
    this.areaKeys = ['china', 'chinadiff', 'south', 'mideast', 'north', 'midsouth', 'taiwan', 'central', 'northeast', 'southwest', 'midwest', 'shaanxi', 'qinghai', 'xinjiang', 'tibet'];
    this.imtypes = ['temp', 'precip1', 'precip6', 'precip24']
    this.imtypes_chn = ['温度', '1小时降水', '6小时降水', '24小时降水']
    this.daily_imtypes = ['tmax', 'tmin']
    this.daily_imtypes_chn = ['最高温', '最低温']

    this.state = {
      imtype: 'temp',
      area: this.areas[0],
      timesel: '最新',
      metatype: 'hourly',
      days: [],
    };
  }

  fetchData(imtype, region) {
    Axios.get(
      "/action/weather/dailymap",
      { params: {type: imtype, region}}
    ).then(res => this.setState({days: res.data.days, timesel: res.data.days[res.data.days.length-1]}))
  }

  render() {
    const entries = this.areas.map(val => ({val: val, disabled: false}));
    let imagePath;
    let akey = this.areaKeys[this.areas.indexOf(this.state.area)];
    if (this.state.area === '') imagePath = '';
    else if (this.state.metatype === 'daily') {
      imagePath = `/protected/latest/weather/daily/${this.state.imtype}_${akey}_${this.state.timesel}.png`;
    }
    else {
      imagePath = `/protected/latest/weather/realtime/${this.state.imtype}_${akey}`;
      if (this.state.timesel === '最新') imagePath = imagePath + '.png';
      else imagePath = imagePath + '_' + pad(this.state.timesel, 2) + '.png';
    }
    return (
      <div className='columns'>
        <div className='column is-2'>
          <PanelList
            heading='选择区域'
            entries={entries}
            onListChange={(i, val) => {
              this.setState({area: ''}, () => {
                this.setState({area: val});
                let newkey = this.areaKeys[this.areas.indexOf(val)];
                if (this.state.metatype === "daily") this.fetchData(this.state.imtype, newkey);
              })}}
            initIndex={0}
          />
        </div>
        <div className='column is-10'>
          <div className='nav-choice-sate is-grouped is-grouped-multiline tags'>
            <a
              className={classnames({'is-active': this.state.metatype === 'hourly'})}
              onClick={() => { this.setState({metatype: "hourly", imtype: 'temp', timesel: '最新'}) }}
            >逐小时</a>
            <a
              className={classnames({'is-active': this.state.metatype === 'daily'})}
              onClick={() => {
                this.setState({metatype: "daily", imtype: 'tmax'});
                this.fetchData('tmax', this.areaKeys[this.areas.indexOf(this.state.area)]);
              }}
            >逐日</a>
          </div>
          <div className='nav-choice-sate is-grouped is-grouped-multiline tags'>
            { this.state.metatype === "hourly"
            ? this.imtypes.map(imt => <a
              className={classnames({'is-active': this.state.imtype === imt})}
              onClick={() => { this.setState({imtype: imt}) }}
            >{this.imtypes_chn[this.imtypes.indexOf(imt)]}</a>)
            : this.daily_imtypes.map(imt => <a
              className={classnames({'is-active': this.state.imtype === imt})}
              onClick={() => {
                this.setState({imtype: imt});
                this.fetchData(imt, this.areaKeys[this.areas.indexOf(this.state.area)])
              }}
            >{this.daily_imtypes_chn[this.daily_imtypes.indexOf(imt)]}</a>)
            }
          </div>
          <div className='select is-rounded is-small'>
            <select onChange={(event) => {
              const newidx = event.target.selectedIndex;
              if (this.state.metatype === "hourly") {
                if (newidx === 0) this.setState({timesel: '最新'});
                else this.setState({timesel: newidx - 1});
              } else {
                this.setState({timesel: event.target.value})
              }
            }}>
              { this.state.metatype === "hourly" && <>
                <option selected={this.state.timesel == '最新'}>最新</option>
                {[...Array(24).keys()].map(item => (
                  <option selected={this.state.timesel == item}>{item}:00</option>
                ))}
              </> }
              { this.state.metatype === "daily" && this.state.days.map(item => (
                <option selected={this.state.timesel === item}>{item}</option>
              ))}
            </select>
          </div>
          <ImageBox src={imagePath} />
        </div>
      </div>
    )
  }
}
