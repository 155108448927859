import Axios from './../../components/_axios';
import React, { useState } from "react";


export default function VideoPage () {
    const [loading, setLoading] = useState(false);

    const makeVideo = key => {
        setLoading(true);
        Axios.get(
            "/action/video/make",
            {params: {key}}
        ).then(res => {
            setLoading(false);
            if (res.data.uri) window.location.href = '/media/' + res.data.uri;
        })
    }

    const VideoButton = ({vkey, text}) => <button
        className='button is-link is-inverted'
        onClick={() => makeVideo(vkey)}
    >{text}</button>;

    return <div>
        <div className='columns'>
            <div className='column is-3 is-hidden-mobile'></div>
            <div className='column is-6 box box-card'>
                <label className='label level is-small'>
                <span className='is-danger'>制作视频</span>
            </label>
            { !loading ? <><VideoButton text='FY4A真彩' vkey='fy4a_truecolor' />
            <VideoButton text='FY2E可见光' vkey='fy2e_vis' />
            <VideoButton text='RAMMB-H8真彩-中国' vkey='rammb_china' />
            <VideoButton text='RAMMB-H8真彩-日本' vkey='rammb_japan' />
            <VideoButton text='雷达-华南' vkey='radar_south' />
            <VideoButton text='雷达-华东' vkey='radar_east' />
            <VideoButton text='雷达-华中' vkey='radar_central' />
            <VideoButton text='雷达-华北' vkey='radar_north' />
            </> : <p>正在加载...</p>}
          </div>
        </div>
    </div>
}
